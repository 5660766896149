import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage, responsive } from "@cloudinary/react"
import { trim } from "@cloudinary/url-gen/actions/reshape"
import mobileStyles from "./mobile.module.css"

const cld = new Cloudinary({
  cloud: {
    cloudName: "thirtymadison"
  }
})

const content = {
  nav: {
    logo: {
      src: cld.image("thirtymadison.com/logo").reshape(trim()),
      alt: "Thirty Madison"
    }
  },
  hero: {
    image: {
      phone: {
        src: cld.image("thirtymadison.com/phone").reshape(trim()),
        alt: "Thirty Madison App Screenshot"
      }
    },
    header: "Science-backed healthcare from the comfort of your home",
    body: "Click the button below to get started.",
    cta: {
      text: "Download for iPhone",
      href: "https://apps.apple.com/us/app/id1623732281"
    }
  }
}

const MobilePage = () => (
  <div className={mobileStyles.wrapper}>
    <nav>
      <ul>
        <li className={mobileStyles.nav__home}>
          <a href="/">HOME</a>
        </li>
        <li className={mobileStyles.nav__logo}>
          <a href="/">
            <AdvancedImage cldImg={content.nav.logo.src} alt={content.nav.logo.alt} />
          </a>
        </li>
        <li className={mobileStyles.nav__cta}>
          <a href={content.hero.cta.href}>Download</a>
        </li>
      </ul>
    </nav>
    <div className={mobileStyles.container}>
      <section className={mobileStyles.hero}>
        <div className={mobileStyles.hero__image}>
          <AdvancedImage cldImg={content.hero.image.phone.src} plugins={[responsive()]} alt={content.hero.image.alt} />
        </div>
        <div className={mobileStyles.hero__content}>
          <div>
            <h1>{content.hero.header}</h1>
            <p>{content.hero.body}</p>
            <div className={mobileStyles.hero__cta}>
              <a href={content.hero.cta.href}>{content.hero.cta.text}</a>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer>
      <div className={mobileStyles.footer__email}>
        <h2>Contact Us</h2>
        <p>
          <a href="mailto:press@thritymadison.com">For media inquiries:&nbsp;press@thirtymadison.com</a>
        </p>
        <p>
          <a href="mailto:careers@thritymadison.com">For career inquiries:&nbsp;careers@thirtymadison.com</a>
        </p>
        <p>
          <a href="mailto:partnerships@thritymadison.com">
            For partnership inquiries:&nbsp;partnerships@thirtymadison.com
          </a>
        </p>
      </div>
      <div className={mobileStyles.footer__brands}>
        <h1>Our Brands</h1>
        <div>
          <p>
            <a href="https://www.keeps.com">Keeps</a>
          </p>
          <p>
            <a href="https://www.withcove.com">Cove</a>
          </p>
          <p>
            <a href="https://www.nurx.com">Nurx</a>
          </p>
        </div>
        <div>
          <p>
            <a href="https://picnic.thirtymadison.com">Picnic</a>
          </p>
          <p>
            <a href="https://facet.thirtymadison.com">Facet</a>
          </p>
        </div>
      </div>
      <div className={mobileStyles.footer__misc}>
        <ul className={mobileStyles.footer__links}>
          <li>
            &copy; Thirty Madison&nbsp;
            {new Date().getFullYear()}
          </li>
          <li>
            <a href="https://patient.thirtymadison.com/dashboard/legals/privacy">Privacy</a>
          </li>
          <li>
            <a href="https://patient.thirtymadison.com/dashboard/legals/terms-and-conditions">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://patient.thirtymadison.com/dashboard/legals/ccpa">CCPA</a>
          </li>
          <li>
            <a href="https://patient.thirtymadison.com/dashboard/legals/informed-consent">Informed Consent</a>
          </li>
        </ul>
        <ul className={mobileStyles.footer__social}>
          <li>
            <a href="https://www.linkedin.com/company/thirty-madison">
              <AdvancedImage cldImg={cld.image("thirtymadison.com/linkedin").reshape(trim())} />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/thirtymadison?lang=en">
              <AdvancedImage cldImg={cld.image("thirtymadison.com/twitter").reshape(trim())} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/thirtymadison/?hl=en">
              <AdvancedImage cldImg={cld.image("thirtymadison.com/instagram").reshape(trim())} />
            </a>
          </li>
          <li className={mobileStyles.footer__logo}>
            <AdvancedImage cldImg={cld.image("thirtymadison.com/tm-short").reshape(trim())} />
          </li>
        </ul>
      </div>
    </footer>
  </div>
)

export default MobilePage
